<template lang="pug">
.search-box
  .search-box__mask(
    v-if="expandable && expanded"
    @click="collapse")

  el-input.search-box__input(
    type="search"
    v-model="keyword"
    placeholder="Search for products, brands, SKUs, UPC, EAN..."
    ref="input"
    @click.native.stop="expand"
    @keypress.native.enter.prevent="open"
    @keydown.native.up.prevent="navigate(-1)"
    @keydown.native.down.prevent="navigate(1)"
    @input="fetch"
    @focus="expand")
    span.vam(slot="append" @click.stop="open")
      i.pf-icon.bx.bx-search

  el-popover.search-box__popover(
    placement="bottom"
    trigger="manual"
    :width="width"
    popper-class="search-box-popper"
    v-show="expandable"
    v-model="expanded")

    //- suggestions-section(
      :selection.sync="selection"
      :keyword="keyword"
      @track="track"
      ref="suggestions")

    template(v-if="shouldShowTrendingKeywords")
      trending-keywords-section(@track="track")
      recent-keywords-section(@track="track")

    template(v-if="shouldShowHistory")
      recent-brands-section(@track="track")
      recent-products-section(@track="track")
</template>

<script>
import { serializeQuery } from '~/utilities/catalog'

// import SuggestionsSection from './SuggestionsSection'
import RecentKeywordsSection from './RecentKeywordsSection'
import TrendingKeywordsSection from './TrendingKeywordsSection'
import RecentBrandsSection from './RecentBrandsSection'
import RecentProductsSection from './RecentProductsSection'

export default {
  components: {
    // SuggestionsSection,
    RecentKeywordsSection,
    TrendingKeywordsSection,
    RecentBrandsSection,
    RecentProductsSection
  },

  provide () {
    return {
      collapse: this.collapse,
      track: this.track,
      notify: this.notify
    }
  },

  data () {
    return {
      keyword: '',
      width: undefined,
      expanded: false,
      typing: false,
      sections: {},
      selection: 0
    }
  },

  computed: {
    shouldShowTrendingKeywords () {
      if (!this.keyword?.trim()?.length) return true // show on empty keyword
      if (!this.typing) return true // show if no keyword changes detected
      // hide for all other cases
      return false
    },

    shouldShowHistory () {
      if (!this.keyword?.trim()?.length) return true // show on empty keyword
      if (!this.typing) return true // show if no keyword changes detected
      // hide for all other cases
      return false
    },

    expandable () {
      if (this.keyword?.trim()?.length) return false // skip suggestions
      const items = Object
        .values(this.sections)
        .reduce((sum, i) => sum + i, 0)
      return items > 0
    }
  },

  watch: {
    $route () {
      this.collapse()
    },

    '$route.query.term': {
      immediate: true,
      handler (val) {
        this.keyword = val
      }
    }
  },

  methods: {
    expand () {
      this.width = this.$refs.input?.$el.offsetWidth
      this.expanded = true
      this.selection = 0
    },

    collapse () {
      this.expanded = false
      this.typing = false
    },

    fetch () {
      this.expand()
      this.typing = true
    },

    navigate (step = 0) {
      this.expanded = true
      this.$refs.suggestions?.navigate(step)
    },

    track (payload = {}) {
      this.$amplitude.track('SEARCH_BOX_ACTION', payload)
      if (['products', 'brands'].includes(payload?.type))
        this.$amplitude.track('SEARCH', { search_query: payload?.keyword })
    },

    notify (key, items = 0) {
      this.$set(this.sections, key, items)
    },

    open () {
      // with suggestions
      this.$refs.suggestions?.open()
      const term = this.keyword?.trim() || ''
      const to = {
        name: 'products',
        query: serializeQuery({
          term
        })
      }
      this.track({
        type: 'products',
        keyword: term
      })
      this.$router.push(to)?.catch(() => {})
      this.collapse()
    },

    focus () {
      this.$refs.input.select()
    }
  }
}
</script>

<style lang="scss">
.search-box-popper {
  padding: 0;
}
</style>

<style lang="scss" scoped>
.search-box {
  $hover: darken(#f5eaf0, 2);
  $frame: 640px;
  max-width: $frame;
  z-index: 100;
  &__mask {
    background: rgba(black, .1);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
  &__input {
    position: relative;
    z-index: 101;
    border: 1px solid rgba(black, .2);
    border-radius: 6px;
    ::v-deep {
      input {
        border: none;
      }
      .el-input-group__append {
        border: none;
        padding: 0 10px;
      }
      .vam {
        cursor: pointer;
      }
    }
  }
  &__popover {
    z-index: 101;
    width: 100%;
    max-width: $frame;
  }
  ::v-deep {
    .search-section {
      &:not(:first-child) {
        border-top: $--border-light;
      }
      &__header {
        padding: 16px 16px 0;
        display: flex;
        gap: 16px;
        font-size: 12px;
        > * {
          min-width: 0;
        }
      }
      &__title {
        color: $--color-text-secondary;
        flex: 1;
      }
      &__clear {
        color: $--color-danger;
      }
      &__content {
        padding: 12px 16px 16px;
      }
    }
    .search-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
      gap: 8px;
      > * {
        min-width: 0;
      }
    }
    .search-stamp {
      display: flex;
      gap: 8px;
      font-size: 12px;
      align-items: center;
      padding: 8px;
      border-radius: 4px;
      &:hover {
        background: $hover;
      }
      > * {
        min-width: 0;
      }
      &__thumbnail {
        flex: 0 36px;
        height: 36px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 4px;
      }
      &__info {
        flex: 1;
        > * {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &__description {
        color: $--color-text-secondary;
      }
    }
  }
}
</style>
